import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "page_header" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { class: "formContainer" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-sm-6" }
const _hoisted_6 = { class: "d-flex align-items-center mb-0" }
const _hoisted_7 = { class: "ms-1" }
const _hoisted_8 = { class: "col-sm-6" }
const _hoisted_9 = { class: "d-flex align-items-center justify-content-between" }
const _hoisted_10 = {
  key: 0,
  class: "d-flex"
}
const _hoisted_11 = ["disabled"]
const _hoisted_12 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_best_combination_table = _resolveComponent("best-combination-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_localized_text, {
        type: 'h1',
        localizedKey: "Eco Pilot",
        text: "Eco Pilot",
        class: "mb-0"
      }),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.bestCombination && _ctx.filters.useUserProviders)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn btn-primary me-2",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.downloadBestCombinationPDF()))
            }, [
              _createVNode(_component_localized_text, {
                localizedKey: "Download PDF",
                text: "Download PDF"
              })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("button", {
          class: "btn btn-tertiary",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openFilters()))
        }, [
          _createVNode(_component_localized_text, {
            localizedKey: "Suggestion Filters",
            text: "Suggestion Filters"
          })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("h2", _hoisted_6, [
            _createVNode(_component_localized_text, {
              localizedKey: "My Estimation",
              text: "My Estimation"
            }),
            _createElementVNode("span", _hoisted_7, "(SKU: " + _toDisplayString(_ctx.originalEstimation.estimation.sku) + ")", 1)
          ]),
          _createVNode(_component_best_combination_table, { model: _ctx.originalEstimation }, null, 8, ["model"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_localized_text, {
              type: 'h2',
              localizedKey: "Suggested",
              text: "Suggested",
              class: "mb-0"
            }),
            (_ctx.bestCombinationList.length > 1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("button", {
                    class: "squareButton left",
                    disabled: _ctx.selectedIndex == 0,
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectedIndex--))
                  }, " ← ", 8, _hoisted_11),
                  _createElementVNode("button", {
                    class: "squareButton right",
                    disabled: _ctx.selectedIndex == _ctx.bestCombinationList.length - 1,
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectedIndex++))
                  }, " → ", 8, _hoisted_12)
                ]))
              : _createCommentVNode("", true)
          ]),
          (_openBlock(), _createBlock(_component_best_combination_table, {
            model: _ctx.bestCombination,
            key: _ctx.selectedIndex
          }, null, 8, ["model"]))
        ])
      ])
    ])
  ], 64))
}